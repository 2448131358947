import { useStyletron } from 'baseui'

const LogoSpan = ({ before, after, ...styles }) => {
	const [css, theme] = useStyletron()
	const pseudoStyle = {
		content: "''",
		display: 'inline-block',
		position: 'absolute',
		height: '2px',
		top: 'calc(50% - 1px)',
		left: '45%',
		transformOrigin: 'center',
		backgroundColor: theme.colors.contentPrimary,
	}
	return (
		<span
			className={css({
				width: '45%',
				height: '100%',
				position: 'relative',
				display: 'block',
				transformOrigin: 'center',
				'::before': { ...pseudoStyle, ...before },
				'::after': { ...pseudoStyle, ...after },
				...styles,
			})}
		/>
	)
}

export default function Logo({ styles }) {
	const [css] = useStyletron()
	return (
		<div className={css({ marginLeft: '10px', width: '46px', display: 'flex', ...styles })}>
			<LogoSpan before={{ width: '100%', transform: 'translateX(-50%) rotate(45deg)' }} after={{ width: '100%', transform: 'translateX(-50%) rotate(-45deg)' }} transform="rotate(45deg)" />
			<LogoSpan before={{ width: 'calc(141% - 1px)', transform: 'translateX(-50%) rotate(45deg)' }} after={{ width: 'calc(141% - 1px)', transform: 'translateX(-50%) rotate(-45deg)' }} />
		</div>
	)
}
